// @flow
import * as React from 'react';
import {Switch, Route} from "react-router-dom";
import {MainView} from "./views/MainView";
import {AddCompanyView} from "./views/AddCompanyView";
import {EditCompanyView} from "./views/EditCompanyView";
import {TripsView} from "./views/TripsView";

type Props = {

};
export const Router = (props: Props) => {
    return (
        <Switch>
            <Route path="/trips">
                <TripsView/>
            </Route>
            <Route path="/companies/add">
                <AddCompanyView/>
            </Route>
            <Route path="/companies/:id">
                <EditCompanyView/>
            </Route>
            <Route path="/">
                <MainView/>
            </Route>
        </Switch>
    );
};