export const getLatLngFromAddress = (adr: string, callback: (result: google.maps.GeocoderResult) => void) => {
    console.log('getLatLngFromAddress', adr)
    const gc = new google.maps.Geocoder();
    gc.geocode(
        {address: adr},
        (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
        ) => {
            console.log('google api response', results);
            if (status === "OK" && results[0]) {
                callback(results[0]);
            }
        }
    );
}

export const getAddressFromLatLng = (position: google.maps.LatLngLiteral, callback: (result: google.maps.GeocoderResult) => void) => {
    console.log('getAddressFromLatLng', position);
    const gc = new google.maps.Geocoder();
    gc.geocode(
        {location: position},
        (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
        ) => {
            if (status === "OK" && results[0]) {
                console.log('google api response', results);
                callback(results[0]);
            }
        }
    );
}

export const extractAddressFromGeocodeResult = (
    result: google.maps.GeocoderResult | undefined
) => {
    if (result === undefined) {
        return {
            street: "",
            country: "",
            city: "",
            province: "",
            post_code: "",
            lat: undefined,
            lng: undefined
        };
    }

    let response = {
        street: result.formatted_address.split(",")[0],
        country: "",
        city: "",
        province: "",
        post_code: "",
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng()
    };

    result.address_components.map((component) => {
        if (component.types.includes("postal_code")) {
            response.post_code = component.long_name;
        }
        if (component.types.includes("country")) {
            response.country = component.long_name;
        }
        if (component.types.includes("locality")) {
            response.city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
            response.province = component.long_name;
        }
    });

    console.log('extractAddressFromGeocodeResult', response);

    return response;
}