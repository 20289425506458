// @flow
import * as React from 'react';
import {useState} from "react";
import {CirclePicker} from "react-color";

type Props = {
    id: string,
    title: string,
    name: string,
    note?: string,
    fullWidth?: boolean,
    onChange?: (hex: string) => void,
    value?: string,
};
export const FormSimpleColor = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const change = (hex: string) => {
        if (props.onChange) {
            props.onChange(hex);
        }
    }

    return (
        <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
            <input name={props.name} type="hidden" ref={ref}/>
            <CirclePicker
                color={props.value}
                onChange={(color => change(color.hex))}
                width={props.fullWidth ? "100%" : undefined}
            />
        </div>
    );
});