// @flow
import * as React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    Grid,
    makeStyles, Menu, MenuItem, TextField,
    Typography
} from "@material-ui/core";
import {useState} from "react";
import {sendDataToServer} from "../../api/sendDataToServer";
import {BackendUrls} from "../../BackendUrls";
import {useQueryClient} from "react-query";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: 4,
    }
}));

export type ClientData = {
    id: number,
    name: string,
    contactData: string,
    notes: string,
    city: string,
    lat: number,
    lng: number,
    address: string,
    color: string,
    description: string,
    phone: string,
    www: string,
    email: string,
}

type Props = {
    data: ClientData,
    onActionClick?: (data: ClientData) => void,
    onDeleteBtn?: (data: ClientData) => void,
    onEditBtn?: (data: ClientData) => void,
    fullWidth?: boolean,
};

export const ClientCard = (props: Props) => {
    const styles = useStyles();
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [noteDialog, setNoteDialog] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>(props.data.notes);
    const [showMenu, setShowMenu] = useState<HTMLButtonElement|null>(null);
    const queryClient = useQueryClient();

    const invoke = (command?: (data: ClientData) => void) => {
        if (command) {
           command(props.data);
        }
    }

    const color = props.data.color ?? 'white';

    function saveNote() {
        sendDataToServer("put", BackendUrls.companies(), {...props.data, notes: notes}).then(response => {
            queryClient.invalidateQueries();
            setNoteDialog(false);
        })
    }

    let gridParams = {lg:4, md:6, sm:6}
    if(props.fullWidth) {
        gridParams = {lg:12, md:12, sm:12};
    }

    function redirectToGoogleMaps(data: ClientData) {
        window.open("https://www.google.pl/maps/place/"+data.city+", "+data.address, "_blank");
    }

    return (
        // @ts-ignore
        <Grid item {...gridParams}>
        <Card className={styles.card}>
            <CardContent>
                <CardActionArea onClick={() => invoke(props.onActionClick)}>
                    <Typography gutterBottom variant="h5" component="h2" style={{display: "flex"}}>
                        {props.data.color && <Avatar style={{backgroundColor: props.data.color, width: "30px", height: "30px", marginRight: "5px"}}>&nbsp;</Avatar>}
                        {props.data.name}
                    </Typography>
                </CardActionArea>
                {props.data.phone !== '' && <Typography variant="body2" color="primary" component="p">Tel: {props.data.phone}</Typography>}
                {props.data.email !== '' && <Typography variant="body2" color="primary" component="p">Email: {props.data.email}</Typography>}
                {props.data.www !== '' && <Typography variant="body2" color="primary" component="p">Www: <a href={props.data.www} target="_blank">{props.data.www}</a></Typography>}
                {props.data.contactData !== '' && <Typography variant="body2" color="primary" component="p">{props.data.contactData}</Typography>}
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.data.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.data.notes}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={() => setNoteDialog(true)}>
                    Notatka
                </Button>
                <Button size="small" color="primary" onClick={() => redirectToGoogleMaps(props.data)}>
                    Trasa
                </Button>
                <Button size="small" color="primary" onClick={(e) => setShowMenu(e.currentTarget)}>
                    Więcej...
                </Button>
                <Menu
                    open={Boolean(showMenu)}
                    anchorEl={showMenu}
                    keepMounted
                    onClose={() => setShowMenu(null)}
                >
                    <MenuItem onClick={() => invoke(props.onEditBtn)}>Edytuj</MenuItem>
                    <MenuItem onClick={() => setDeleteDialog(true)}>Usuń</MenuItem>
                </Menu>
            </CardActions>
        </Card>
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Usunąć "+props.data.name+"?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Uuuuu.... kasowanie danych... nie fajnie... czy na pewno?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} color="primary">
                        NIE NIE NIE to przypadkiem było
                    </Button>
                    <Button onClick={() => invoke(props.onDeleteBtn)} color="primary" autoFocus>
                        Usuń
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={noteDialog}
                onClose={() => setNoteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Notatka do "+props.data.name+"?"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="Notatka"
                        type="text"
                        value={notes}
                        multiline={true}
                        rows={1}
                        rowsMax={4}
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => saveNote()} color="primary" autoFocus>
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};