// @flow
import * as React from 'react';
import {Divider, Grid, GridSize, makeStyles, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

type Props = {
    title?: string,
    children?: React.ReactNode,
    xs?: GridSize,
    md?: GridSize,
    lg?: GridSize,
    headerComponents?: React.ReactNode
};
export const Box = (props: Props) => {
    const styles = useStyles();
    return (
        <Grid item xs={props.xs ?? 'auto'} md={props.md ?? 'auto'} lg={props.lg ?? 'auto'}>
            <Paper className={styles.paper}>
                <Grid container>
                    {props.title && <Grid item xs={12} md={6} lg={6}><Typography component="h2" variant="h6" color="primary" gutterBottom>{props.title}</Typography></Grid>}
                    <Grid item xs={12} md={6} lg={6}><div style={{float:"right"}}>{props.headerComponents}</div></Grid>
                </Grid>
                <Divider/>
                {props.children}
            </Paper>
        </Grid>
    );
};