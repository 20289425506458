import {Box} from "../components/layout/Box";

type Props = {

};
export const TripsView = (props: Props) => {
    return (
        <div>
            <Box title={"Wyjazdy"} xs={12} md={6} lg={7}>

            </Box>
            <Box title={"Dodaj nowy wyjazd"} xs={12} md={6} lg={7}>

            </Box>
        </div>
    );
};