import React from 'react';
import {GoogleMap, LoadScript} from '@react-google-maps/api';

type Client = {
    id: number,
    name: string,
    color: string,
    position: google.maps.LatLngLiteral
}

type Props = {
    clients?: Client[],
    center: google.maps.LatLngLiteral,
    zoom: number,
    children?: React.ReactNode,
    markerColor?: string,
    onClick?: (e: google.maps.MapMouseEvent) => void
}

export const defaultPosition: google.maps.LatLngLiteral = {lat: 52.172286233675955, lng:19.111364881249994};
export const defaultZoom: number = 6;
export const selectZoom: number = 10;

function MyComponent(props: Props) {
    //return <div style={{height: "500px", backgroundColor: "lightGreen"}}>darmowy odpowiendik map</div>;

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
            <GoogleMap
                mapContainerStyle={{width: 'auto', height: '500px'}}
                center={props.center}
                zoom={props.zoom}
                onClick={props.onClick}
                //options={{styles: mapStyle}}
            >
                { /* Child components, suoch as markers, info windows, etc. */}
                <>
                    {props.children}
                </>
            </GoogleMap>
        </LoadScript>
    )
}

export default MyComponent