import {useQuery} from "react-query";
import {BackendUrls} from "../BackendUrls";

export type Company = {
    id: number,
    name: string,
    contactData: string,
    notes: string,
    city: string,
    lat: number,
    lng: number,
    address: string,
    color: string,
    description: string,
    phone: string,
    www: string,
    email: string,
    province: string,
}

export interface CompaniesFiltersData {
    search: string,
    province: string,
    color: string
}

export const GetCompanies = () => useQuery<Company[]>("GetCompaniesQuery", () => {
    return fetch(BackendUrls.companies()).then(res => res.json());
}, {
    cacheTime: 1000 * 60 * 5
});

export const filterData = (data: Company[], filters: CompaniesFiltersData) : Company[] => {
    let filteredData = data;
    if(filteredData == undefined) return [];

    // Szukaj
    filteredData = (filters.search === '') ? filteredData : filteredData.filter(
        (c) =>
            c.name.toLowerCase().search(filters.search) >= 0 ||
            c.address.toLowerCase().search(filters.search) >= 0 ||
            c.city.toLowerCase().search(filters.search) >= 0 ||
            c.contactData.toLowerCase().search(filters.search) >= 0 ||
            c.notes.toLowerCase().search(filters.search) >= 0
    );

    //Województwo
    filteredData = (['brak', ''].includes(filters.province)) ? filteredData : filteredData.filter(
        (c) => c.province.toLowerCase() === filters.province.toLowerCase()
    );

    // Kolor
    filteredData = (filters.color === '') ? filteredData : filteredData.filter(
        (c) => c.color.toLowerCase().search(filters.color.toLowerCase()) >= 0
    );

    return filteredData;
}