// @flow
import * as React from 'react';
import {Button, ButtonGroup, FormControl, Grid, InputLabel, TextField} from "@material-ui/core";
import {FormSimpleColor} from "../components/input/FormSimpleColor";
import {useState} from "react";
import Map, {defaultPosition, defaultZoom} from "../components/map/Map";
import {useForm, Controller} from "react-hook-form";
import {extractAddressFromGeocodeResult, getAddressFromLatLng, getLatLngFromAddress} from "../api/Google";
import {CustomMarker} from "../components/map/CustomMarker";
import {BackendUrls} from "../BackendUrls";
import {useHistory} from "react-router-dom";
import {useQueryClient} from 'react-query'
import {sendDataToServer} from "../api/sendDataToServer";
import {Company} from "../api/Companies";
import {ProvinceSelect} from "../components/input/ProvinceSelect";

type Props = {
    company?: Company
};

export const CompanyForm = (props: Props) => {

    const editValues = props.company ?? {
        id: undefined,
        name: '',
        contactData: '',
        notes: '',
        city: '',
        lat: undefined,
        lng: undefined,
        address: '',
        color: "#f44336",
        description: '',
        province: 'brak',
    };

    const { register, handleSubmit, watch, errors, getValues, setValue, control } = useForm({
        defaultValues: editValues
    });
    const position = (editValues.lat && editValues.lng) ? {lat: editValues.lat, lng: editValues.lng} : defaultPosition;
    const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>(position);
    const [mapZoom, setMapZoom] = useState<number>(defaultZoom);
    const [mapMarkerPosition, setMapMarkerPosition] = useState<google.maps.LatLngLiteral>(position);
    const history = useHistory();
    const queryClient = useQueryClient();
    const color = watch('color');

    const onSubmit = (data: any) => {
        sendDataToServer(data.id > 0 ? "put" : "post", BackendUrls.companies(), data).then(response => {
            queryClient.invalidateQueries();
            history.push("/");
        })
    }

    const changeColor = (newColor: string) => {
        setValue('color', newColor);
    }

    function findMarkerPosition() {
        const data = getValues();
        getLatLngFromAddress(data.city+", "+data.address, (result) => {
            const l = result.geometry.location;
            setValue('lat', l.lat());
            setValue('lng', l.lng());
            setMapMarkerPosition(l.toJSON());
            setMapCenter(l.toJSON());
            setMapZoom(10);
        })
    }

    function findAddress() {
        const data = getValues();
        console.log('findAddress', data);
        getAddressFromLatLng({lat: parseFloat(data.lat.toString()), lng: parseFloat(data.lng.toString())}, (result) => {
            const address = extractAddressFromGeocodeResult(result);
            setValue('city', address.city);
            setValue('address', address.street);
            setValue('province', address.province.toLowerCase());
        })
    }

    function placePin(e: google.maps.MapMouseEvent) {
        let pos = e.latLng.toJSON();
        setValue('lat', pos.lat);
        setValue('lng', pos.lng);
        setMapMarkerPosition(pos);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container justify={"center"} alignItems={"stretch"}>
                <Grid item xs={12} lg={6}>
                    <div style={{paddingRight: "10px"}}>
                        <input type="hidden" name="id" ref={register}/>
                        <TextField label={"Nazwa firmy"} name={"name"} variant={"outlined"} fullWidth inputRef={register}/>
                        <TextField label={"Dane kontaktowe"} name={"contactData"} variant={"outlined"} fullWidth inputRef={register}/>
                        <TextField label={"Notatka"} name={"notes"} variant={"outlined"} fullWidth inputRef={register} multiline={true} rows={1} rowsMax={4}/>
                        <TextField label={"Telefon"} name={"phone"} variant={"outlined"} fullWidth inputRef={register}/>
                        <TextField label={"Email"} name={"email"} variant={"outlined"} fullWidth inputRef={register}/>
                        <TextField label={"www"} name={"www"} variant={"outlined"} fullWidth inputRef={register}/>
                        <TextField label={"Opis - Czym sie zajmuje firma"} name={"description"} variant={"outlined"} fullWidth inputRef={register} multiline={true} rows={1} rowsMax={4}/>
                        <TextField label={"Miasto"} name={"city"} variant={"outlined"} fullWidth inputRef={register}/>
                        <FormControl fullWidth variant={"outlined"} >
                            <InputLabel>Województwo</InputLabel>
                            <Controller
                                as={<ProvinceSelect label={"Województwo"} name={"province"} fullWidth inputRef={register}/>}
                                name={"province"}
                                control={control}
                                defaultValue={"brak"}
                            />
                        </FormControl>
                        <TextField label={"Adres"} name={"address"} variant={"outlined"} fullWidth inputRef={register}/>
                        <TextField name={"lat"} variant={"outlined"} fullWidth={false} inputRef={register}/>
                        <TextField name={"lng"} variant={"outlined"} fullWidth={false} inputRef={register}/>
                        <Button variant="outlined" color="primary" onClick={() => findMarkerPosition()}>Ustaw marker</Button>
                        <Button variant="outlined" color="primary" onClick={() => findAddress()}>Ustal adres</Button>
                        <FormSimpleColor id={"comp-color"} title={"Kolor"} name={"color"} fullWidth={true} onChange={changeColor} value={color} ref={register}/>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Map
                        center={mapCenter}
                        zoom={mapZoom}
                        onClick={placePin}
                    >
                        {<CustomMarker color={color} position={mapMarkerPosition} draggable={true} onDragEnd={placePin}/>}
                    </Map>
                </Grid>
            </Grid>
            <ButtonGroup>
                <Button color="primary" type="submit">Zapisz</Button>
            </ButtonGroup>
        </form>
    );
};