// @flow
import * as React from 'react';
import {
    Button,
    ButtonGroup, Divider,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress, List, ListItem,
    Menu,
    MenuItem, Select,
    TextField
} from "@material-ui/core";
import {Box} from "../components/layout/Box";
import {ClientCard, ClientData} from "../components/clientCard/ClientCard";
import Map, {defaultPosition, defaultZoom} from "../components/map/Map";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {CustomMarker} from "../components/map/CustomMarker";
import {CompaniesFiltersData, Company, filterData, GetCompanies} from "../api/Companies";
import {sendDataToServer} from "../api/sendDataToServer";
import {BackendUrls} from "../BackendUrls";
import {useQueryClient} from "react-query";
import {InfoWindow} from "@react-google-maps/api";
import {FormSimpleColor} from "../components/input/FormSimpleColor";
import {PagesButtons} from "../components/PagesButtons";
import {ProvinceSelect} from "../components/input/ProvinceSelect";

export const MainView = () => {
    const cardsPerPage = 20;
    const [page, setPage] = useState<number>(1);
    const [centeredMap, setCenteredMap] = useState<google.maps.LatLngLiteral>(defaultPosition);
    const [mapZoom, setMapZoom] = useState<number>(defaultZoom);
    const {isLoading, isError, error, data} = GetCompanies();
    const [filteredData, setFilteredData] = useState<Company[]>([]);
    const initialFilter = {
        search: '',
        province: 'brak',
        color: ''
    };
    const [filters, setFilters] = useState<CompaniesFiltersData>(initialFilter);

    const [selectedMarkerId, setSelectedMarkerId] = useState<Company | undefined>(undefined);
    const [showFiltersMenu, setShowFiltersMenu] = useState<null | HTMLElement>(null);
    let history = useHistory();
    const queryClient = useQueryClient();

    useEffect(() => {
        setFilteredData(filterData(data ?? [], filters));
    }, [filters, data])

    const centerMap = (newPos: google.maps.LatLngLiteral, zoom: number) => {
        setCenteredMap(newPos);
        setMapZoom(zoom);
    };

    const deleteCompany = (data: ClientData) => {
        sendDataToServer('delete', BackendUrls.companiesId(data.id.toString()), {}).then(response => {
            queryClient.invalidateQueries();
        })
    };

    const editCompany = (data: ClientData) => {
        history.push("/companies/" + data.id);
    };


    if (isLoading || !data) {
        return <Box title="Klienci" xs={12} md={6} lg={7}>
            <LinearProgress/>
        </Box>
    }

    if (isError) {
        return <Box title="Klienci" xs={12} md={6} lg={7}>
            {JSON.stringify(error)}
        </Box>
    }

    return <Grid container spacing={3}>
        <Box title="Klienci" xs={12} md={6} lg={7} headerComponents={
            <>
                <ButtonGroup color="primary">
                    <Button onClick={() => history.push("/companies/add")}>Dodaj</Button>
                    <Button onClick={(e) => setShowFiltersMenu(e.currentTarget)}>
                        Wyszukaj
                    </Button>
                </ButtonGroup>
                <Menu
                    id="filters-menu"
                    anchorEl={showFiltersMenu}
                    keepMounted
                    open={Boolean(showFiltersMenu)}
                    onClose={() => setShowFiltersMenu(null)}
                >
                    <List>
                        <ListItem>
                            <TextField
                                placeholder={"Szukaj..."}
                                //style={{paddingLeft: "10px"}}
                                fullWidth
                                value={filters.search}
                                onChange={(e) => setFilters({...filters, search: e.target.value.toLowerCase()})}
                            />
                        </ListItem>
                        <ListItem>
                            <FormControl fullWidth>
                                <InputLabel># Województwo</InputLabel>
                                <ProvinceSelect
                                    value={filters.province}
                                    onChange={(e) => setFilters({
                                        ...filters,
                                        province: (e.target.value as string).toLowerCase()
                                    })}
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <FormSimpleColor id={'filters-color'} title={'Kolor'} name={'fitlers-color'}
                                             value={filters.color}
                                             onChange={(color) => setFilters({...filters, color: color})}/>
                        </ListItem>
                        <ListItem>
                            <Button variant="outlined" onClick={() => setFilters(initialFilter)}>Wyczyść</Button>
                        </ListItem>
                    </List>
                </Menu>
            </>
        }>
            <Grid container>
                <Grid container>
                    <PagesButtons itemsPerPage={cardsPerPage} total={filteredData.length} page={page}
                                  onChange={setPage}/>
                </Grid>
                {filteredData.slice((page - 1) * cardsPerPage, page * cardsPerPage).map((client) =>
                    <ClientCard
                        data={client}
                        onActionClick={(data) => centerMap({lat: data.lat, lng: data.lng}, 10)}
                        onDeleteBtn={deleteCompany}
                        onEditBtn={editCompany}
                    />
                )}
                <Grid container>
                    <PagesButtons itemsPerPage={cardsPerPage} total={filteredData.length} page={page}
                                  onChange={setPage}/>
                </Grid>
            </Grid>
        </Box>
        <Box title="Mapa" xs={12} md={6} lg={5}>
            <Map center={centeredMap} zoom={mapZoom}>
                {filteredData.map((client) => (
                    (selectedMarkerId && selectedMarkerId.id === client.id)
                        ? <InfoWindow position={{lat: client.lat, lng: client.lng}}
                                      onCloseClick={() => setSelectedMarkerId(undefined)}>
                            <div>
                                <ClientCard
                                    fullWidth={true}
                                    data={client}
                                    onDeleteBtn={deleteCompany}
                                    onEditBtn={editCompany}
                                />
                            </div>
                        </InfoWindow>
                        :
                        <CustomMarker key={client.id} position={{lat: client.lat, lng: client.lng}} color={client.color}
                                      onClick={() => setSelectedMarkerId(client)}/>
                ))}
            </Map>
        </Box>
    </Grid>;
};