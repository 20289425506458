// @flow
import * as React from 'react';
import {Grid, LinearProgress} from "@material-ui/core";
import {Box} from "../components/layout/Box";
import {CompanyForm} from "../forms/CompanyForm";
import {useParams} from "react-router-dom";
import {Company, GetCompanies} from "../api/Companies";

type Props = {

};

interface ParamTypes {
    id: string
}

export const EditCompanyView = (props: Props) => {
    const { id } = useParams<ParamTypes>();
    const {isLoading, isError, error, data} = GetCompanies();

    if (isLoading || data == null) {
        return <Box title="Edytuj firmę" xs={12} md={12} lg={12}>
            <LinearProgress/>
        </Box>;
    }

    const company = data.find((company: Company) => company.id.toString() === id);

    if (company === undefined) {
        return <Box title={"Nie ma takiej firmy"} xs={12} md={12} lg={12}/>
    }

    return <Grid container spacing={3}>
        <Box title={"Edytuj "+company.name} xs={12} md={12} lg={12}>
            <CompanyForm company={company}/>
        </Box>
    </Grid>;
};