// @flow
import * as React from 'react';
import {Button, Checkbox, Container, FormControlLabel, Grid, makeStyles, Paper, TextField} from "@material-ui/core";
import {useState} from "react";
import { Face, Fingerprint } from '@material-ui/icons'

type Props = {
    onPasswordEnter: (pass: string) => void;
};

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing() * 2,
    },
    padding: {
        padding: theme.spacing()
    },
    app: {
        margin: "auto",
        width: "40%"
    }
}));

export const LoginPage = (props: Props) => {
    const [pass, setPass] = useState<string>(localStorage.getItem('eac-baza-klientow-pass') || '');
    const classes = useStyles();

    const setPassValue = (pass: string) => {
        localStorage.setItem('eac-baza-klientow-pass', pass);
        setPass(pass);
    }

    return (
        <Container maxWidth="sm">
            <Paper className={classes.padding}>
                <div className={classes.margin}>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Face />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="username" label="Login" type="email" fullWidth autoFocus />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Fingerprint />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField type="password" label="Hasło" value={pass} fullWidth onChange={(e) => setPassValue(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={() => props.onPasswordEnter(pass)}>Zaloguj</Button>
                    </Grid>
                </div>
            </Paper>
        </Container>
    );
};