import * as React from 'react';
import {Button, ButtonGroup} from "@material-ui/core";

interface Props {
    itemsPerPage: number,
    total: number,
    page: number,
    onChange: (page: number) => void
};

export const PagesButtons = (props: Props) => {
    const pages = Math.ceil(props.total / props.itemsPerPage);
    const currentPage = props.page;

    if (props.page > pages && pages > 0) {
        props.onChange(pages);
    }

    return (
        <ButtonGroup variant={"outlined"}>
            {Array.from(Array(pages).keys()).map((i) => {
                const page = i + 1;
                const isCurrentPage = page === currentPage;
                if (isCurrentPage) {
                    return <Button
                        color="primary"
                        variant="contained"
                        onClick={() => props.onChange(page)}
                    >
                        {i + 1}
                    </Button>;
                } else {
                    return <Button
                        color="default"
                        variant="outlined"
                        onClick={() => props.onChange(page)}
                    >
                        {i + 1}
                    </Button>;
                }
            })}
        </ButtonGroup>
    );
};