import * as React from 'react';
import {MenuItem, Select, SelectProps} from "@material-ui/core";

interface Props extends SelectProps {

};

export const ProvinceSelect = (props: Props) => {
    return (
        <Select
            {...props}
        >
            {
                [
                    'brak', 'dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie',
                    'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie',
                    'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie',
                    'wielkopolskie', 'zachodniopomorskie'
                ]
                    .map((n) => <MenuItem value={n}>{n}</MenuItem>)}
        </Select>
    );
};