import React, {useState} from 'react';
import {HeaderBar} from "./components/layout/HeaderBar";
import {Container, makeStyles} from "@material-ui/core";
import {BrowserRouter} from "react-router-dom";
import {Router} from "./Router";
import {QueryClient, QueryClientProvider} from 'react-query'
import {LoginPage} from "./views/LoginPage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

const queryClient = new QueryClient();

function App() {
    const styles = useStyles();
    const [fakeAuthentication, setFakeAuthentication] = useState<string>(localStorage.getItem('eac-baza-klientow-pass') || '');

    if(fakeAuthentication !== 'asdasd1212') {
        return <LoginPage onPasswordEnter={setFakeAuthentication}/>
    }

    const logout = () => {
        setFakeAuthentication('');
        localStorage.setItem('eac-baza-klientow-pass', '');
    }

    return (
        <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <div className="App">
                <HeaderBar onLogoutBtn={logout}/>
                <div style={{height: '60px'}}>&nbsp;</div>
                <Container maxWidth="xl" className={styles.container}>
                    <Router/>
                </Container>
            </div>
        </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
