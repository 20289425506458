// @flow
import * as React from 'react';
import {Grid} from "@material-ui/core";
import {Box} from "../components/layout/Box";
import {CompanyForm} from "../forms/CompanyForm";

type Props = {

};
export const AddCompanyView = (props: Props) => {
    return <Grid container spacing={3}>
        <Box title="Dodaj firmę" xs={12} md={12} lg={12}>
            <CompanyForm/>
        </Box>
    </Grid>;
};