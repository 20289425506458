// Example POST method implementation:
import axios, {AxiosRequestConfig} from "axios";

export const sendDataToServer = (method: 'get'|'post'|'put'|'delete', url: string, data: object, config?: AxiosRequestConfig) => {
    let params = new URLSearchParams();
    for (const [key, value] of Object.entries(data)) {
        if(value !== undefined) {
            params.append(key, value);
        }
    }
    switch (method) {
        case "get": return axios.get(url+'?'+params.toString(), config);
        case "put": return axios.put(url, params, config);
        case "delete": return axios.delete(url+'?'+params.toString(), config);
        case "post":
        default:
            return axios.post(url, params, config);
    }
}