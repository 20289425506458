// @flow
import * as React from 'react';
import {
    AppBar,
    Button,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

type Props = {
    onLogoutBtn: () => void
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        textDecoration: 'none'
    }
}));

export const HeaderBar = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    function routeTo(s: string) {
        return function (p1: React.MouseEvent<HTMLSpanElement>) {
            history.push(s);
        };
    }

    return (
        <AppBar>
            <Toolbar>
                <Typography variant="h6" className={classes.title} onClick={routeTo("/")}>
                    Baza Klientów EAC
                </Typography>
                <Button color="inherit" onClick={routeTo("/companies/add")}>Dodaj Firmę</Button>
                <Button color="inherit" onClick={routeTo("/trips")}>Wyjazdy</Button>
                <Button color="inherit" onClick={() => props.onLogoutBtn()}>
                    Wyloguj
                </Button>
            </Toolbar>
        </AppBar>
    );
};